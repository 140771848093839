
import missingImg from 'images/missing.png'
import styles from './BlockedUser.module.scss'
import useProfileStore from 'store/ProfileStore'

const BlockedUser = ({user}) => {
  const {unblockUser} = useProfileStore()

  return (
    <div className={styles.user}>
      <img
        src={
          user.attributes.first_name
            ? user.attributes.small_image_url || missingImg
            : missingImg
        }
        alt=""
      />

      <div className={styles.userName}>
        <strong>
          {user.attributes.first_name
            ? `${user.attributes.first_name} ${user.attributes.last_name}`
            : 'Deleted User'}
        </strong>
      </div>

      <div className={styles.unblock} onClick={() => unblockUser(user?.id)}>Unblock</div>
    </div>
  )
}

export default BlockedUser
