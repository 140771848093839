import _ from 'underscore'
import React, {useEffect} from 'react'

import useProfileStore from 'store/ProfileStore'
import BlockedUser from 'components/BlockedUser/BlockedUser'
import styles from './BlockedUsers.module.scss'

const BlockedUsers = ({}) => {
  const {blockedUsers, getBlockedUsers} = useProfileStore()

  useEffect(() => {
    getBlockedUsers()
  }, [getBlockedUsers])

  // const renderReviews = () => {
  //   return reviews.map((review, key) => {
  //     const {trip, user} = review?.relationships || {}
  //     return <ReviewCard key={key} trip={trip} user={user} review={review} />
  //   })
  // }

  return (
    <div className="my-trips">
      <h4>Blocked Users</h4>
      

      
      <div className="col s12">
        {blockedUsers.length > 0 ? 
        blockedUsers.map((user, key) => 
          <BlockedUser user={user} key={key} />
        ) : 
          <div className={styles.empty}>No block users, yet</div>
        }
        
      </div>
      

      {/* <div className="trips-container">
        {reviews.length > 0 ? (
          renderReviews()
        ) : dataLoaded ? (
          <h4 className={styles.empty}>No Pending Reviews!</h4>
        ) : (
          <CircularLoading noPadding />
        )}
      </div> */}
    </div>
  )
}

export default BlockedUsers
