import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {notify} from 'react-notify-toast'
import {useUser} from 'context/UserProvider'
import useUserStore from 'store/UserStore'
import useSessionStore from 'store/SessionStore'
import ProfileMainInfo from 'components/ProfileMainInfo/ProfileMainInfo'
import CircularLoading from 'ui/CircularLoading/CircularLoading'

const ProfileDetails = ({isMyProfile}) => {
  const navigate = useNavigate()
  const {userId} = useParams()
  const {user: currentUser, setUser} = useUser()
  const {getCurrentUserRequest} = useSessionStore()
  const {user, getUserRequest} = useUserStore()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const loadMyProfile = async () => {
      const res = await getCurrentUserRequest()
      if (!res.errors) {
        setUser(res.data)
      }
    }

    loadMyProfile()
  }, [getCurrentUserRequest, setUser])

  useEffect(() => {
    const loadUserInfo = async () => {
      setLoading(true)
      const res = await getUserRequest(userId)
      if (res.errors) {
        notify.show(res.errors, 'error')
        navigate('/')
      }
      setLoading(false)
    }
    if (userId) {
      console.log('load user info')
      loadUserInfo()
    }
  }, [getUserRequest, navigate, userId])

  if (loading) {
    return <CircularLoading />
  }

  return <ProfileMainInfo user={isMyProfile ? currentUser : user} />
}

export default ProfileDetails
