import {Route, Routes} from 'react-router-dom'
import Dashboard from 'screens/common/Dashboard/Dashboard'
import Home from 'screens/common/Home'
import NoScreen from 'screens/common/NoScreen'
import Trust from 'screens/common/Trust'
import AboutUs from 'screens/common/AboutUs'
import Careers from 'screens/common/Careers'
import Help from 'screens/common/Help'
import Policies from 'screens/common/Policies'
import Terms from 'screens/common/Terms'
import ChatList from 'screens/member/ChatList/ChatList'
import ProfileDetails from 'screens/member/ProfileDetails/ProfileDetails'
import EditProfile from 'screens/member/EditProfile'
import Chat from 'screens/member/Chat/Chat'
import NewRide from 'screens/member/NewRide/NewRide'
import MyTrips from 'screens/member/MyTrips'
import TripDetails from 'screens/member/TripDetails'
import Requests from 'screens/member/Requests'
import Review from 'screens/member/Reviews/Reviews'
import ProfileChecklist from 'screens/member/ProfileChecklist'
import RiderChecklist from 'screens/member/RiderChecklist'
import EditRide from 'screens/member/EditRide'
import ReviewForm from 'screens/member/ReviewForm'
import BlockedUsers from 'screens/member/BlockedUsers/BlockedUsers'

const MemberRouter = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/safety" element={<Trust />} />
    <Route path="/about" element={<AboutUs />} />
    <Route path="/careers" element={<Careers />} />
    <Route path="/help" element={<Help />} />
    <Route path="/policies" element={<Policies />} />
    <Route path="/terms" element={<Terms />} />
    <Route path="/search" element={<Dashboard />} />
    <Route path="/chatList" element={<ChatList />} />
    <Route path="/my_profile" element={<ProfileDetails isMyProfile />} />
    <Route path="/edit_profile" element={<EditProfile />} />
    <Route path="/chat" element={<Chat />} />
    <Route path="/new_ride" element={<NewRide />} />
    <Route path="/my_rides" element={<MyTrips />} />
    <Route path="/ride/:rideId" element={<TripDetails />} />
    <Route path="/requests" element={<Requests />} />
    <Route path="/reviews/:reviewId" element={<ReviewForm />} />
    <Route path="/reviews" element={<Review />} />
    <Route path="/blocked_users" element={<BlockedUsers />} />
    <Route path="/profile/:userId" element={<ProfileDetails />} />
    <Route path="/complete_profile" element={<ProfileChecklist />} />
    <Route path="/profile_details" element={<RiderChecklist />} />
    <Route path="/edit_ride/:rideId/" element={<EditRide />} />
    <Route path="*" element={<NoScreen />} />
  </Routes>
)

export default MemberRouter
