import {create} from 'zustand'
import callApi from 'util/apiCaller'

const useProfileStore = create(set => ({
  blockedUsers: [],
  errors: [],

  getBlockedUsers: () => {
    callApi(`profiles/blocks`).then(res => {
      set({blockedUsers: res.data})
    })
  },

  blockUser: (id) => {
    return callApi(`profiles/${id}/block`, 'post').then(res => {
      callApi(`profiles/blocks`).then(res => {
        set({blockedUsers: res.data})
      })
    })
  },

  unblockUser: (id) => {
    return callApi(`profiles/${id}/unblock`, 'post').then(res => {
      callApi(`profiles/blocks`).then(res => {
        set({blockedUsers: res.data})
      })
    })
  },

  reportUser: (id, tripId, message) => {
    return callApi(`profiles/${id}/report`, 'post', {
      message,
      trip_id: tripId,
    }).then(res => {
    })
  }
}))

export default useProfileStore
