import React, {useState} from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import Dialog from '@material-ui/core/Dialog'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css';
import styles from './TripMenu/TripMenu.module.scss'
import useProfileStore from 'store/ProfileStore'
import { Button, TextField } from '@material-ui/core'

const ReportMenu = ({
  userId,
  tripId,
  isBlocked,
  on_menu_open,
  on_menu_closed,
  on_trip_cancelled,
  on_request_cancelled,
  onBlockChanged,
}) => {
  const initial_state = {
    anchorEl: null,
    is_open: false,
    is_dialog_open: false,
    menu_open_cb: on_menu_open || (() => {}),
    menu_closed_cb: on_menu_closed || (() => {}),
    trip_cancelled_cb: on_trip_cancelled || (() => {}),
    request_cancelled_cb: on_request_cancelled || (() => {}),
  }

  const [state, setState] = useState(initial_state)
  const [message, setMessage] = useState('')

  const { blockUser, unblockUser, reportUser } = useProfileStore()

  const handleClick = (event) => {
    setState({
      ...state,
      is_open: true,
      anchorEl: event.currentTarget,
    })
    // don't forward to parent
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    event.preventDefault()
  }

  const handleClose = () => {
    setState({
      ...state,
      is_open: false,
      anchorEl: null,
    })
  }

  const handleDialogOpen = async () => {
    handleClose()
    if (isBlocked) {
      await unblockUser(userId)
      onBlockChanged()
      
    } else {
      confirmAlert({
        title: 'Block User?',
        message: "Are you sure you want to block this user? You won't be able to chat this user after block.",
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              await blockUser(userId)
              onBlockChanged()
            }
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }
  }

  const handleReportModalOpen = () => {
    setState({
      ...state,
      is_open: false,
      is_dialog_open: true,
    })
  }

  const handleDialogClose = (event, reason) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    event.preventDefault()
    setState({
      ...state,
      is_dialog_open: false,
    })
    setMessage('')
  }

  const handleReportUser = () => {
    if (message.trim().length === 0) return

    reportUser(userId, tripId, message)

    setState({
      ...state,
      is_dialog_open: false,
    })

    setMessage('')
  }

  return (
    <div
      style={{
				position: 'absolute',
				top: 10,
				right: 10,
			}}
      onMouseEnter={on_menu_open}
      onMouseLeave={on_menu_closed}>
      <IconButton
        aria-owns={state.is_open ? `report-menu` : undefined}
        aria-haspopup="true"
        onClick={handleClick}
				className={styles.reportDropdown}
				>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id={`report-menu`}
        open={state.is_open}
        anchorEl={state.anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            transform: 'translateX(-10%)',
            width: 150,
            padding: 0,
          },
        }}
        MenuListProps={{style: {padding: 0}}}
        className="trip-dropdown">
        <MenuItem onClick={handleReportModalOpen}>
					<Icon className="menu-icon">person</Icon>Report User
        </MenuItem>
				<MenuItem onClick={handleDialogOpen}>
          <Icon className="menu-icon">person</Icon>
          { isBlocked ? 'Unblock User' : 'Block User' }
        </MenuItem>
      </Menu>
      <Dialog
        open={state.is_dialog_open || false}
        onClose={handleDialogClose}
        className="dialog-box"
        maxWidth="sm"
        fullWidth={true}>
        <div className="dialog-heading">
          <Icon className="close-icon right" onClick={handleDialogClose}>
            close
          </Icon>
          <h3>Report User</h3>
        </div>
        <div className="dialog-body">
          Please write why you report this user.
        </div>
        <TextField
          multiline
          maxRows={5}
          value={message}
          style={{
            marginLeft: 16,
            marginRight: 16
          }}
          onChange={(event) => setMessage(event.target.value)}
        />

        <Button
          variant="contained"
          color="primary"
          style={{margin: 16}}
          onClick={handleReportUser}>
          Report User
        </Button>
      </Dialog>
    </div>
  )
}

export default ReportMenu
